import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const AboutWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
`;

const AboutPage = () => (
    <Layout>
        <Helmet title="About | unicorn.computer" />
        <AboutWrapper>
            <h1>About</h1>
            <section>
                <details>
                    <summary>What is this?</summary>
                    <p>
                        This is a satirical news site about things tech-related.
                        Programming languages, cloud computing, startups, that
                        sort of thing.
                    </p>
                </details>
                <details>
                    <summary>Why would you do this?</summary>
                    <p>
                        I (@markthethomas) made it while dealing with the global
                        Covid-19 pandemic because I thought we could all use
                        some laughs.
                    </p>
                </details>
                <details>
                    <summary>is any of this serious?</summary>
                    <p>no.</p>
                </details>
                <details>
                    <summary>is this satire?</summary>
                    <p>yes</p>
                </details>
                <details>
                    <summary>what is satire?</summary>
                    <p>
                        <a href="https://lmgtfy.com/?q=satire">satire</a>
                    </p>
                </details>
                <details>
                    <summary>how do I get in touch?</summary>
                    <p>
                        Feel free to email me at
                        <a href="mailto:hello@ifelse.io">hello@ifelse.io</a>
                    </p>
                </details>
            </section>
        </AboutWrapper>
    </Layout>
);

export default AboutPage;
